<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Clientes potenciales</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <loader v-if="isloading"></loader>


        <v-row dense>
          <v-col cols="12">
            <v-btn @click="$emit('open_config', config)" class="white--text" color="blue">configuración</v-btn>
          </v-col>

          <v-col class="mt-4" cols="12">
            <v-alert v-model="alert" dense outlined type="warning">
              Por favor configura los tipos de visa para obtener el listado de clientes
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.user_doc="{ item }">
                <span>{{ item.user_doc.name }} {{item.user_doc.last_name}}</span>
              </template>

              <template v-slot:item.image="{ item }">
                <router-link :to="`/chat?id=${item.ownerId}`">
                  <v-avatar>
                    <img :src="item.user_doc.image.includes('uploads') ? `${$awsPath}/${item.user_doc.image}` : item.image">
                  </v-avatar>
                </router-link>
                <span class="ml-3">{{item.name}}</span>
              </template>

              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | format_date}}</span>
              </template>

              <template v-slot:item.chat="{ item }">
                <router-link :to="`/chat?id=${item.ownerId}`">
                  <v-icon color="green">mdi-chat-processing</v-icon>
                </router-link>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <modal-config-leads v-on:config_saved="updateConfig" :visa_types="visa_types" :visa_status="visa_status"></modal-config-leads>
  </v-container>
</template>

<script>
  import {
    lead_service
  } from '~/lead_service'

  import {
    config_user_service
  } from '~/config_user_service'

  import moment from 'moment'

  import "moment/locale/es"

  import modalConfigLeads from './modalConfigLeads'

  export default {
    components: {
      'modal-config-leads': modalConfigLeads
    },
    data() {
      return {
        alert: false,
        config: {
          visaTypes: [],
          visaStatus: [],
          message: null
        },
        visa_status: [],

        visa_types: [],

        items: [],

        headers: [{
            text: '',
            value: 'image',
          }, {
            text: 'Usuario',
            value: 'user_doc',
          },
          /*{
            text: 'Último mensaje',
            value: 'created_at',
          }, */
          {
            text: 'Chat',
            value: 'chat',
          }
        ],
      }
    },

    created() {
      this.getVisaStatus()
      this.getUserConfig()
    },

    mounted() {
      //if (this.user.email == 'danielvkp@live.com') {
      this.getUserLeads()
      //}
    },

    methods: {
      updateConfig(data) {
        this.config = JSON.parse(JSON.stringify(data))
        this.alert = false
        this.getUserLeads()
      },

      getUserLeads() {
        lead_service.get_user_leads().then(res => {
          if (res.data.status == true) {
            this.items = res.data.leads.leads
          } else {
            this.$toast.warn('no se han encontrado casos, por favor actualiza tus filtros')
          }
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },
      invertUser(item) {
        return {
          id: item._id,
          image: item.image.includes('uploads') ? `${$awsPath}/${item.image}` : item.image,
          name: item.name,
          created_at: item.createdAt
        }
      },
      getUserConfig() {
        lead_service.get_user_config().then(res => {
          if (res.data) {
            this.config = res.data
          } else {
            this.alert = true
          }
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },
      getVisaStatus() {
        this.visa_status = config_user_service.get_action_codes()
        this.visa_types = config_user_service.get_form_nums()
      }
    },

    filters: {
      user_image() {

      },
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },

      format_date(db_date) {
        return moment(db_date).format('dddd, D MMMM - Y hh:mm a')
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>