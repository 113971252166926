<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Leads calificados</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <loader v-if="isloading"></loader>


        <v-row dense>

          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">
              <template v-slot:item.status="{item}">
                <v-chip @click="updateContacto(item)" class="white--text" small dense :color="item.status ? 'green' : 'orange'">{{item.status ? 'contactado' : 'pendiente'}}</v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {
    upload_lead_service
  } from '~/upload_lead_service'


  export default {
    data() {
      return {

        items: [],

        headers: [{
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Mensaje',
            value: 'other',
            width: 200
          },
          {
            text: 'Teléfono',
            value: 'phone',
          },
          {
            text: 'contacto',
            value: 'status',
          }
        ],
      }
    },

    created() {
      this.getUserLeads()
    },


    methods: {
      getUserLeads() {
        upload_lead_service.get_by_user().then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando clientes')
        })
      },

      updateContacto(item) {
        upload_lead_service.update_contacto(item.id, item).then(res => {
          let index = this.items.indexOf(item)
          this.$set(this.items, index, res.data)
          this.$toast.sucs('contacto actualizado con exito')
        }, res => {
          this.$toast.error('Error actualizando clientes')
        })
      }
    },

    filters: {
      user_image() {

      },
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },

      format_date(db_date) {
        return moment(db_date).format('dddd, D MMMM - Y hh:mm a')
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>